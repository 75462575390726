<div id="wrap">
    <navbar id="navbar"
        [hoverColor]="hoverColor"
        [initialColor]="initialColor"
        [backgroundColor]="backgroundColor">
    </navbar>
    <img src="../../assets/gfx/o_nas/circle1.png" id="img1">
    <div class="bgimages" [innerHtml]="backgroundImages | safeHtml">
    </div>

    <div class="row container-fluid" id="content">
        <p [innerHtml]="contentTitle | safeHtml" id="contentTitle"></p>
        <div class="" id="menu">
            <ul id="menuList">
                <li class="align-text-top">
                    <a (click)="changeContent('us', $event);" class="selected">o nas</a>
                </li>
                <li>
                    <a (click)="changeContent('perssonel', $event)">kadra</a>
                </li>
                <li>
                    <a (click)="changeContent('association', $event)">stowarzyszenie</a>
                </li>
                <li>
                    <a (click)="changeContent('projects', $event)">projekty</a>
                </li>
                <li>
                    <a (click)="changeContent('statute', $event)">statut przedszkola</a>
                </li>
                <li>
                    <a (click)="changeContent('report', $event)">raport z ewaluacji</a>
                </li>
            </ul>
        </div>
        <div class="" id="aspect">
            <p [innerHtml]="content"></p>
        </div>
    </div>
</div>
<div class="bg"></div>
<footer id="footer" class="pt-4"></footer>