<nav class="navbar navbar-expand-lg navbar-light sticky-top sticky" id="navbar-container">
    <a routerLink="/main" class="navbar-brand img-fluid" id="mainPageButton">
        <img src="{{logoPath}}">
    </a>
    <button id="navButton" class="navbar-toggler" type="button" data-target="" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" (click)="toggleExpand()"></span>
    </button>
    <div class="overlay" id="myNav" [ngClass]="expanded ? 'navbarHidden' : 'navbarExpanded'">
        <div class="">
            <ul>
                <li data-toggle="collapse" class="collapsed-active" data-target="#about_us" aria-expanded="false">
                    <a>o nas</a>
                    <ol class="sub-menu collapse" id="about_us">
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us/us" (click)="toggleExpand()" data-toggle="collapse" data-target="#about_us" href="#">o nas</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us/perssonel" (click)="toggleExpand()" data-toggle="collapse" data-target="#about_us" href="#">kadra</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us/association" (click)="toggleExpand()" data-toggle="collapse" data-target="#about_us" href="#">stowarzyszenie</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us/projects" (click)="toggleExpand()" data-toggle="collapse" data-target="#about_us" href="#">projekty</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us/statute" (click)="toggleExpand()" data-toggle="collapse" data-target="#about_us" href="#">statut przedszkola</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us/report" (click)="toggleExpand()" data-toggle="collapse" data-target="#about_us" href="#">raport z ewaluacji</a>
                        </li>
                    </ol>
                </li>
                <li data-toggle="collapse" class="collapsed-active" data-target="#offer" aria-expanded="false">
                    <a>oferta</a>
                    <ol class="collapse" id="offer">
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/offer/offer" (click)="toggleExpand()">oferta</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/offer/activities" (click)="toggleExpand()">nasze zajęcia</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/offer/schedule" (click)="toggleExpand()">plan zajęć</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/offer/building" (click)="toggleExpand()">budynek</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/offer/priceList" (click)="toggleExpand()">cennik</a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/offer/catering" (click)="toggleExpand()">oferta cateringowa</a>
                        </li>
                    </ol>
                </li>
                <li>
                    <a class="col-9 offset-3 pb-3 pt-3" (click)="toggleExpand()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" class="nav-item" routerLink="/recruitment" href="#">rekrutacja</a>
                </li>
                <li>
                    <a class="col-9 offset-3 pb-3 pt-3" (click)="toggleExpand()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" class="nav-item" routerLink="/gallery" href="#">galeria</a>
                </li>
                <li>
                    <a class="col-9 offset-3 pb-3 pt-3" (click)="toggleExpand()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" class="nav-item" routerLink="/contact" href="#">kontakt</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto" id="navList">
            <li>
                <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/about_us" class="nav-link font-weight-bold" href="#">o nas</a>
            </li>
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/offer" class="nav-link font-weight-bold" href="#">oferta</a>
            </li>
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" class="nav-item">
                <a routerLink="/recruitment" class="nav-link font-weight-bold" href="#">rekrutacja</a>
            </li>
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" class="nav-item">
                <a routerLink="/gallery" class="nav-link font-weight-bold" href="#">galeria</a>
            </li>
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" class="nav-item">
                <a routerLink="/contact" class="nav-link font-weight-bold" href="#">kontakt</a>
            </li>
        </ul>
    </div>
</nav>