import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileService } from '../../core/services/mobile.service';

declare var $: any;
@Component({
  templateUrl: './offer-component.html',
  styleUrls: ['./offer-component.css'],
})

export class OfferComponent {
  content: string;
  contentTitle: string;
  contentList: Array<any>;
  hoverColor = 'rgb(255,136,0)';
  initialColor = 'white';
  backgroundColor = '#006da1';
  isMobileDevice: boolean;
  backgroundImages: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private isMobileService: MobileService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.content = '';
    this.contentTitle = '';
    this.contentList = [
      {
        name: 'offer',
        displayName: 'oferta',
        text: `<p>Placówka mieści się w nowym wielofunkcyjnym budynku, należącym do
          Gminy Wielka Wieś, wyposażonym w pomieszczenia przystosowane do edukacji
          dzieci. Przy budynku znajduje się plac zabaw oraz duży parking.
          <strong>Przedszkole przeznaczone jest dla dzieci w wieku od 2,5 do 5
          lat.</strong> Realizuje program edukacyjno-dydaktyczny rekomendowany
          przez MEN. Grono specjalistów czuwa nad prawidłowym rozwojem przedszkolaków
          i rozwojem ich talentów. W „Zaczarowanym Ołówku” dzieci uczą się, bawią
          i poznają świat pod opieką pedagogów wychowania przedszkolnego, psychologa
          oraz pedagogów sztuki rozumiejących dzieci i ich potrzeby. Oprócz
          realizowania podstawy programowej przedszkolaki biorą  udział w zajęciach
          dodatkowych, takich jak: zajęcia taneczne i umuzykalniające, zajęcia
          logopedyczne, judo, zajęcia gimnastyczne, warsztaty psychoedukacyjne oraz
          warsztaty artystyczne prowadzone według autorskiego programu.</p>
          <br>
          <p><strong>Pragniemy zapewnić Państwa dzieciom:</strong></p>
          <p>Przygotowanie do osiągnięcia gotowości szkolnej.</p>
          <p>Rozwijanie wrażliwości emocjonalnej, umiejętności współpracy, oraz innych
            umiejętności społecznych, niezbędnych do nawiązywania pierwszych przyjaźni
            i budowania dobrych relacji w grupie rówieśników.</p>
          <p>Rozwijanie sprawności ruchowej dzieci i kształtowanie zdrowych nawyków
            poprzez codzienną gimnastykę oraz spacery i zabawy na świeżym powietrzu.
            Rozwój u dzieci wrażliwości estetycznej i umiejętności wypowiadania się
            przez sztuki plastyczne, muzykę i małe formy teatralne. Poprzez zabawy
            artystyczne dzieci rozwijają nie tylko wyobraźnię i sprawność manualną
            potrzebną podczas nauki w szkole ale również umiejętność otwartej komunikacji
            i współpracy w grupie. Najważniejszym efektem naszych zajęć jest dziecięca
            radość i satysfakcja z efektów własnej pracy!</p>
          <p>Zajęcia dodatkowe wspomagające rozwój fizyczny i intelektualny dzieci
            (rytmika, zajęcia językowe)</p>
          <p>Różnego rodzaju atrakcje; wycieczki, występy teatrzyku, udział w
            konkursach, wyjazdy do teatru lub muzeum, uroczystości okolicznościowe.</p>
          <p>Współpracę z rodziną w procesie wychowania.</p>`
      },
      {
        name: 'activities',
        displayName: 'nasze zajęcia',
        text: `<p>Oferta przedszkola obejmuje realizację podstawy programowej
            zgodnie z programem nauczania przedszkolnego zatwierdzonym przez MEN
            oraz zajęcia dodatkowe: - artystyczne, językowe i sportowe, prowadzone
            przez profesjonalistów:
          <br>
          <strong>Zajęcia z języka angielskiego</strong>, na których prowadzący
            rozmawia z dziećmi tylko po angielsku</p>
          <p><strong>Judo</strong>, prowadzone przez trenerów sportowych, byłych
            zawodników klubu sportowego Wisła Kraków, medalistów Mistrzostw Polski
            i Pucharu Polski w tej dyscyplinie.</p>
          <p>Gimnastykę korekcyjną prowadzona przez nauczycieli AWF</p>
          <p><strong>Zajęcia muzyczno-rytmiczne</strong>, prowadzone przez
            absolwentów Akademii Muzycznej w Krakowie.</p>
          <p><strong>Autorskie warsztaty artystyczne z elementami wiedzy o
            sztuce</strong> opracowane specjalnie dla najmłodszych. Na warsztatach
            pracujemy wszystkimi technikami i narzędziami dostępnym dla dzieci, na
            różnych materiałach, także na bardzo dużych formatach. Warsztaty są
            prowadzone przez magistra edukacji artystycznej.</p>
          <p><strong>Zajęcia z logopedą</strong> – Prowadzimy wczesną naukę
            czytania z użyciem metody symultaniczno-sekwencyjnej profesor Jagody
            Cieszyńskiej oraz indywidualne zajęcia korygujące. Zajęcia indywidualne
            prowadzone w naszym przedszkolu obejmują wczesną diagnozę umiejętności
            komunikacyjnych i w razie potrzeby korekcję zaburzeń mowy, a także
            szeroko rozumianą profilaktykę.ak: zajęcia taneczne i umuzykalniające,
            zajęcia logopedyczne, judo, gimnastyka, warsztaty psychoedukacyjne
            oraz w warsztatach artystycznych prowadzonych według autorskiego
            programu.</p>`
      },
      {
        name: 'judo',
        displayName: 'judo',
        text: `<p><strong>Judo</strong>
            <br>
            Zajęcia Ippon Judo – mini judo to zajęcia ogólnorozwojowe,
            kształtujące wytrzymałość, szybkość, siłę, zręczność, koordynację oraz
            orientację przestrzenną. Zajęcia mają na celu wszechstronne rozwijanie
            tych cech poprzez gry i zabawy ruchowe, czyli jedną z najbardziej
            atrakcyjnych form zajęć ruchowych dla dzieci. Podczas ćwiczeń oprócz
            zabawy, dzieci uczą się umiejętności upadania, co gwarantuje
            bezpieczeństwo podczas zajęć. Stopień intensywności ćwiczeń dostosowany
            jest do możliwości rozwojowych dzieci. Dziecko rozwija wszystkie
            podstawowe cechy motoryczne, a także uczy się wzajemnego szacunku,
            godnego zachowania oraz wzmacnia poczucie własnej wartości. Judo jest
            zarówno sztuką walki jak i sportem walki. Dzieci przygotowują się do
            wielu wyzwań, ponieważ w życiu, jak w judo, nie zawsze wygrywają.
            Podczas zajęć dzieci uczą się wytrwałości, ale także tego, iż wygrywanie
            nie jest najważniejsze. Judo, jako że jest sztuką walki, zawiera w
            sobie elementy samoobrony, jednak na etapie zajęć przedszkolnych
            największy nacisk kładziemy na naukę miękkiego upadania. Żaden trener
            nie uczy swoich zawodników zachowań agresywnych. Dzięki treningom judo
            dzieci rozwijają cechy motoryczne takie jak gibkość, siła, wytrzymałość,
            koordynacja, szybkość, zwinność. Zajęcia trwają 30 min i odbywają się
            na specjalnych matach. Zapewniamy maty które rozkładamy na powierzchni
            20 m<sup>2</sup> .Odbywają się z reguły raz w tygodniu. grupy maksymalnie
            15 osobowe. Wiek dzieci 4-6lat, niekiedy 3 latki również dają radę
            uczestniczyć w tego typu zajęciach. Potrzebny strój to zwykła koszulka
            i spodenki , na matę wchodzimy boso. Posiadamy samych doświadczonych
            instruktorów JUDO z wieloletnim stażem zawodniczym jak i trenerskim
            oraz przede wszystkim z prawidłowym podejściem do dzieci. Trenerzy
            posiadają tytuł instruktora JUDO. Prowadzimy zajęcia w całym Krakowie
            i okolicach. Mamy trzy filie klubowe gdzie cześć dzieciaków przechodzi
            kończąc przygodę w przedszkolu oraz w których można odrobić zajęcia w
            przypadku przepadnięcia ich w przedszkolu.</p>`
      },
      {
        name: 'english',
        displayName: 'język angielski',
        text: `<p><strong>język angielski</strong>
          <br>
            Potrzeba komunikacji jest jedną z podstawowych potrzeb człowieka.
            Dzieci są ciekawe świata i uwielbiają poznawać nowe rzeczy. Wykorzystujemy
            tą aktywność do wdrażania języka angielskiego.</p>
          <p>Ośrodek kształcenia w zakresie języka angielskiego "SWING" specjalizuje
            się w prowadzeniu zajęć dla dzieci w wieku przedszkolnym. Naszym celem
            jest zagwarantowanie dzieciom dobrego startu lingwistycznego, bo wiemy
            jak ważne jest osłuchanie z językiem obcym, jego melodią i strukturą
            od najmłodszych lat. Naszym założeniem jest, aby język angielski stał
            się przyjemną zabawą, tak jak czas spędzany na huśtawce. W trakcie
            lekcji śpiewane są piosenki, organizowane są quizy oraz prowadzone
            są zadania ruchowe. Czas trwania zajęć dostosowujemy do wieku dzieci.</p>
          <p>Zapewniamy dzieciom:<br>
          – wykwalifikowanych lektorów<br>
          – zajęcia prowadzone w formie zabawy ( gry ruchowe, słowne i in.)<br>
          – prowadzenie zajęć z wykorzystywaniem pomocy dydaktycznych w postaci
            kart obrazkowych, historyjek obrazkowych, gier dydaktycznych,<br>
          – czas i wielkość grupy dostosowaną do wieku dzieci.</p>
          <p>Zajęcia są prowadzone przez Ośrodek Kształcenia w Zakresie Języka
            Angielskiego "SWING"</p>`
      },
      {
        name: 'artWorkshop',
        displayName: 'warsztaty plastyczne',
        text: `<p><strong>warsztaty plastyczne</strong>
            <br>
            Warsztaty artystyczne w naszym przedszkolu są prowadzone w każdy
            piątek, według opracowanego przeze nas własnego programu, obejmującego
            między innymi nauczanie elementów historii sztuki. Podczas takich zajęć
            dzieci oglądają reprodukcje, słuchają krótkiej prelekcji nauczyciela a
            potem opowiadają o swoich wrażeniach i tworzą własne interpretacje dzieł
            sztuki z którymi się zapoznały - rysunki naskalne, japoński parawan czy
            afrykańską maskę.</p>
          <p>W trakcie zajęć plastycznych używamy wszystkich technik dostępnych dla
            małych dzieci: rysunku, malarstwa, collage, naklejanek z różnych
            materiałów, bawimy się kolorowymi masami plastycznymi i tworzymy rzeźby
            z gotowych przedmiotów.
            <br>
            Często pracujemy na bardzo dużych formatach – nasze prace zbiorowe mają
            zwykle około 3 metrów długości. Używamy wszystkich rodzajów pędzli,
            nawet tych do malowania ścian, tak dużych ja tylko może używać
            przedszkolak. Malujemy na papierze, folii, kartonach i szklanych
            przedmiotach. Naszej wyobraźni nic nie ogranicza.
            <br>
            Celem zajęć jest rozwijanie kreatywności i samodzielności – każde
            dziecko jest słuchane, traktowanie indywidualnie, zachęcane do
            realizowania własnych pomysłów i chwalone za aktywność. Zwracamy też
            uwagę na rozwijanie umiejętności społecznych, czyli dobrą współpracę
            i porozumienie podczas tworzenia prac grupowych: każdy ma prawo do
            własnej przestrzeni ale podkreślamy też, jak ważny jest wzajemny
            szacunek, pomaganie innym i dzielenie się materiałami.</p>
        <p>Efektem zajęć jest nie tylko rozwijanie wrażliwości artystycznej i
        zdobywanie wiedzy o sztuce, ale też rozwój umiejętności motorycznych i
        poczucia sprawczości oraz duma i radość z samodzielnego wykonywania
        pięknych rzeczy. Efekty naszej pracy można podziwiać <a href="#/gallery/0" style="color: orange;">TU</a></p>`
      },
      {
        name: 'rhythmicity',
        displayName: 'rytmika',
        text: `<p><strong>rytmika</strong>
            <br>
            Celem rytmiki w przedszkolu jest ogólne umuzykalnienie oraz
            rozwój poczucia rytmu, wrażliwości, poczucia estetyki i ekspresji
            muzycznej. Zajęcia obejmują śpiew, grę na instrumentach, a także
            zapewniają dużo ruchu. Dzieci uczą się rozpoznawać i reagować na
            zmiany tempa, faktury, dynamiki, rejestru, długości dźwięku oraz
            ogólnego nastroju w muzyce. Śpiewanie tematycznych piosenek wiąże się
            ze wzmocnieniem głosu oraz zwiększeniem swobody oddechu i polepszeniem
            dykcji.  Interpretacje ruchowe wspomagają koordynację ruchową,
            kształcąc jednocześnie w dzieciach wrażliwość, wyobraźnię muzyczną
            oraz lepsze zrozumienie melodii. Jednocześnie rozwija się u nich pamięć
            muzyczna i umiejętność koncentracji.</p>
          <p>Zajęcia przewidują także ćwiczenia rytmiczne, takie jak rytmizacja
          słów i wyrażeń, dokładne powtarzanie prostych rytmów za nauczycielką
          czy klaskanie i taniec w rytm muzyki. Rytmika daje także dzieciom
          możliwość zapoznania się z różnymi instrumentami perkusyjnymi oraz
          doświadczenia wspólnego muzykowania i improwizacji, co przy okazji
          integruje grupę.</p>`
      },
      {
        name: 'gymnastics',
        displayName: 'gimnastyka',
        text: `<p><strong>gimnastyka</strong>
            <br>
            Zajęcia gimnastyki korekcyjno-kompensacyjnej mają na celu
            działanie profilaktyczne, zapobieganie wadom postawy, kształtowanie
            nawyku postawy prawidłowej. jak również zapewnienie przedszkolakom
            bardzo potrzebnej zabawy poprzez ruch. Wszystkie te cele realizujemy
            poprzez ćwiczenia ogólnorozwojowe, oddechowe, wyrabiające nawyk
            prawidłowej postawy ciała oraz wzmacnianie mięśni posturalnych.
            Najczęściej na zajęciach stosowana jest forma zabawowa, która
            stymuluje dzieci do jeszcze większej aktywności ruchowej. W parze z
            gimnastyką idzie kompensacja, która ma na celu wyrównanie niewłaściwych,
            często codziennych zachowań i przyzwyczajeń.<br>
          <strong>Drużyna Kangura</strong><br>
            Program zajęć Drużyny Kangura jest formą zachęcenia dzieci do podejmowania
            aktywności fizycznej opartej na ćwiczeniach z piłkami. Jest on odpowiedzią na potrzebę
            realizacji zainteresowań ruchowych przedszkolaków oraz ich rodziców, chcących
            zapewnić swoim dzieciom jak najlepsze warunki do rozwoju fizycznego i motorycznego.
            Zajęcia są prowadzone przez cały rok przedszkolny, a więc zgodnie z zaleceniami
            Światowej Organizacji Zdrowia dotyczącymi systematyczności wysiłku fizycznego.
            Spełniony jest też drugi warunek ŚOZ, mianowicie każde zajęcia trwają na tyle długo, aby
            utrwaliły się korzystne efekty zdrowotne ruchu. Tym samym Przedszkoliada.pl wyrabia u
            dzieci potrzebę regularnej aktywności fizycznej.<br>
            Celem prowadzonych zajęć jest:<br>
            – polepszenie ogólnego stanu zdrowia,<br>
            – uświadomienie korzyści wynikających z aktywności fizycznej,<br>
            – ukulturalnianie fizyczne dziecka,<br>
            – zapewnienie harmonijnego rozwoju fizycznego dziecka,<br>
            – przygotowanie mentalne do współpracy w grupie, zdrowej rywalizacji,
              umiejętności, pogodzenia się z przegraną oraz szacunku do
              przeciwnika,<br>
            – popularyzacja aktywności fizycznej u przedszkolaków poprzez zabawę
              i ćwiczenia.<br>
            Program jest realizowany w wymiarze jednych zajęć tygodniowo w grupach maksymalnie
            12-osobowych. Czas trwania zajęć jednej grupy to 30 min., choć w przypadku dzieci w
            wieku trzech lat realizujemy także zajęcia krótsze. Realizacja jednego cyklu programu
            zajmuje minimum dziewięć z dwunastu miesięcy. Realizacja całości programu zajmuje
            minimum trzy, a kompleksowo cztery lata.
            Program realizowany jest przez wykwalifikowanych instruktorów Przedszkoliada.pl,
            czasem także w towarzystwie zaproszonych gości (sportowców w roli autorytetów dzieci).</p>
            <div class="container no-gutters p-0">
              <div class="row">
                <div class="col-12 col-md-6 ">
                  <img src="../../../assets/gfx/oferta/gimnastyka_1.jpg" class="img-fluid">
                </div>
                <div class="col-12 col-md-6">
                  <img src="../../../assets/gfx/oferta/gimnastyka_2.jpg" class="img-fluid">
                </div>
                <div class="col-12 col-md-10 pt-0-md pt-5">
                  <img src="../../../assets/gfx/oferta/gimnastyka_stopka.png" class="img-fluid">
                </div>
              </div>
            </div>`
      },
      {
        name: 'speech',
        displayName: 'zajęcia logopedyczne',
        text: `<p><strong>zajęcia logopedyczne</strong>
            <br>
            Logopedia to dziedzina nauki zajmująca się nie tylko
            eliminowaniem wad wymowy, ale także usuwaniem barier w komunikacji
            językowej, które mogą zaburzać rozwój intelektualny, społeczny i
            emocjonalny dziecka. Zaburzenia mowy powstają na różnym tle, dlatego
            terapeuta – by je zdiagnozować, a potem leczyć – musi mieć rzetelną
            wiedzę z zakresu medycyny, psychologii a także pedagogiki.<br>
            <br>
            <strong>Logopeda:</strong><br>
            – kształtuje prawidłową mowę w trakcie jej rozwoju<br>
            – usuwa zaburzenia i wady wymowy<br>
            – uczy mowy w przypadku jej braku lub utraty<br>
            – zapobiega negatywnemu oddziaływaniu wady wymowy lub choroby na
              psychikę pacjenta<br>
            <br>
            <strong>Czym zajmuje się logopeda w przedszkolu?</strong><br>
            Logopeda w przedszkolu przede wszystkim zajmuje się:<br>
            – profilaktyką logopedyczną oraz terapią zaburzeń komunikacji<br>
            – oceną stanu rozwoju mowy<br>
            – usprawnianiem narządów artykulacyjnych oraz korygowaniem wad wymowy<br>
            – usuwaniem trudności w pisaniu i czytaniu<br>
            – wspieraniem ogólnego rozwoju dzieci, w tym aktywności psychoruchowych<br>
            <br>
            <strong>Dlaczego warto wspierać rozwój mowy?</strong><br>
            Dziecko dzięki rozumieniu mowy poznaje otaczający go świat, a dzięki
            mowie potrafi wyrazić swoje spostrzeżenia czy uczucia. Kontakty słowne
            dziecka z otoczeniem przyspieszają jego rozwój, wzbogacają słownictwo,
            doskonalą wymowę. Stopień rozwoju mowy zależy również od od środowiska
            oraz rozwoju psychoruchowego. Brak wsparcia, zaniedbania środowiskowe
            czy brak prawidłowych wzorców językowych mogą opóźniać rozwój mowy
            dzieci, co skutkuje trudnościami w nauce w późniejszych etapach nauki
            oraz problemami społeczno-emocjonalnymi.<br>
            <br>
            <strong>Co wspiera rozwój mowy?</strong><br>
            1. Rozmawianie z dzieckiem.<br>
            2. Dbanie o kontakt wzrokowy.<br>
            3. Unikanie zdrobnień.<br>
            4. Wspólne rysowanie, śpiewanie, tańczenie.<br>
            5. Gimnastyka buzi i języka, ćwiczenia oddechowe.<br>
            6. Zadawanie pytań i odpowiadanie na pytania dzieci.<br>
            7. Zwracanie uwagi na dźwięki otoczenia.<br>
            8. Ćwiczenia motoryki małej: przesypywanie, wyklejanie, wydzieranie.<br>
            9. Układanie i opowiadanie historyjek obrazkowych.<br>
            10. Zabawa :) Wszystkie te ćwiczenia powinny być dla dziecka przede wszystkim dobrą
              zabawą.<br>
            <br>
            <strong>Jak wyglądają zajęcia logopedyczne w przedszkolu?</strong><br>
            Zajęcia odbywają się raz w tygodniu i trwają około 20 minut. Podczas
            zajęć ćwiczymy artykulację, uczymy się czytać Symultaniczno-Sekwencyjną
            Metodą Nauki Czytania, ćwiczymy motorykę małą, ćwiczymy narrację
            opowiadając obrazki i historyjki obrazkowe, rozwiązujemy łamigłówki,
            a przede wszystkim dobrze się bawimy :)<br>
            Do czego potrzebny jest zeszyt?<br>
            Zeszyt logopedyczny pomaga nam zebrać wszystkie ćwiczenia, które
            wykonaliśmy. Dzieci mogą zabierać zeszyty do domu w celu utrwalenia
            materiału, dostają również ćwiczenia i zabawy do wykonania w domu.</p>
            <p><strong>Martyna Piotrowicz - logopeda<br>
            m.piotrowicz@zaczarowany.com</strong></p>`
      },
      {
        name: 'psychologicalConsultations',
        displayName: 'konsultacje psychologiczne',
        text: `<p><strong>konsultacje psychologiczne</strong>
            <br>
            <br>
            <strong>Czym zajmuje się psychologia?</strong>
            <br>
            Psychologia to nauka, która bada mechanizmy i prawa rządzące psychiką
            oraz zachowaniami człowieka. W jej obrębie znajdują się różne dziedziny,
            takie jak: psychologia poznawcza, psycholingwistyka, psychologia rozwoju
            człowieka, psychologia osobowości, psychologia społeczna, neuropsychologia,
            psychofizjologia, psychologia ewolucyjna oraz psychopatologia i
            psychoterapia. Psychologia jako nauka cały czas się rozwija, czerpie
            i graniczy z wieloma innymi np. medycyną, biologią, pedagogiką,
            socjologią i filozofią.</p>
          <br>
          <p><strong>Czym zajmuje się psycholog w przedszkolu?</strong></p>
          <p><i>Dla dzieci:</i><br>
            Rolą psychologa dziecięcego w przedszkolu jest przede wszystkim
            obserwacja i wspieranie rozwoju dzieci. Okres wieku przedszkolnego
            jest czasem intensywnych i bardzo dynamicznych zmian na wszystkich
            płaszczyznach rozwojowych. Zadaniem psychologa jest obserwacja i
            nadzorowanie wspólnie z wychowawcą czy przebieg i tempo zmian są
            prawidłowe. Dzieci, u których podejrzewa się dysfunkcje rozwojowe,
            pozostają pod szczególną obserwacją i opieką psychologa, który
            rozpoznaje ich indywidualne potrzeby edukacyjne i rozwojowe, a w
            razie potrzeby wprowadza dalsze działania terapeutyczne. Zadaniem
            psychologa jest także rozpoznawanie mocnych stron dzieci i pomaganie
            im w czynieniu dalszych postępów. Psycholog w przedszkolu regularnie
            prowadzi dla grupy zajęcia integracyjne i terapeutyczne, które służą
            rozwojowi oraz wzmocnieniu ich kompetencji emocjonalnych, społecznych,
            uwagowych, komunikacyjnych oraz samoregulacyjnych. W sytuacji
            zaobserwowania szczególnych trudności występujących w grupie
            przedszkolnej, psycholog znajduje możliwe rozwiązania danej sytuacji
            kryzysowej oraz koncentruje się na wsparciu grupy jako całości oraz
            poszczególnych jej członków. W pracy psychologa ważna jest także pomoc
            indywidualna, która jest ukierunkowana na konkretne dziecko i jego
            trudności oraz dostosowana do jego potrzeb i możliwości.</p>
          <p><i>Dla rodziców:</i><br>
            Psycholog przedszkolny jest nie tylko dla dzieci, ale także dla
            rodziców. Na początku współpracy z rodzicem, jego zadaniem jest pomoc
            opiekunom w przejściu przez okres adaptacji dziecka do przedszkola.
            Wsparcie to jest udzielane poprzez dawanie wskazówek jak przygotować
            siebie oraz pociechę do nowej sytuacji tak, by łagodnie i możliwie
            szybko przejść ten trudny czas. Po okresie adaptacji, każdy rodzic
            może zgłosić się do psychologa,
            szczególnie, jeżeli mierzy się z trudnościami w sferze wychowawczej,
            lub zaobserwował u dziecka zaburzenia w sferze emocjonalnej,
            społecznej lub komunikacyjnej. W takich sytuacjach psycholog dzieli
            się z nimi swoimi obserwacjami, wspiera i udziela wskazówek. Nie
            ocenia, zamiast tego wspomaga zdolności rodzicielskie przez zachętę
            do wprowadzenia nowych rozwiązań i metod wychowawczych. Psycholog
            wspiera rodzica emocjonalnie oraz informacyjnie, a kiedy zachodzi
            taka potrzeba, może skierować go na konsultację z innym
            specjalistą.</p>
          <br>
          <p><strong>Dlaczego psycholog jest potrzebny w przedszkolu?</strong>
            <br>
            Okres wczesnego i średniego dzieciństwa, w którym znajdują się
            przedszkolaki, jest czasem bardzo intensywnego i szybkiego rozwoju w
            sferze fizycznej, poznawczej, społecznej oraz emocjonalnej. W
            przypadku opóźnienia lub zaburzenia rozwoju kluczowe jest jak
            najszybsze ich wykrycie i podjęcie działań z zakresu wczesnego wspomagania
            rozwoju. Takie rozpoznanie jest zadaniem psychologa współpracującego
            z wychowawcą grupy i gronem pedagogicznym w placówce</p>`
      },
      {
        name: 'dogotherapy',
        displayName: 'dogoterapia',
        text: `<p><strong>Dogoterapia</strong>
            <br>
            <br>
            <strong>Czym jest dogoterapia?</strong>
            <br>
            Odpowiedź jest prosta, to radość naszych futrzanych przyjaciół wnoszona w
            życie naszych pociech. Nie werbalna relacja między nimi, działa motywująco na
            obie strony, zachęcając do zabawy i nauki. Poprzez zabawę wykonywana jest praca,
            nie wymuszona, natomiast realizująca cele terapeutyczne i dydaktyczne. Co ważne,
            emocje wpływają także na wiele sfer funkcjonowania. Oddziałują pozytywnie na
            procesy poznawcze, motywację, samoocenę, koncentrację i zdrowie.</p>
          <br>
          <p>Dogoterapia ma wiele zalet, poprzez terapię z udziałem psa realizujemy m.in.:</p>
          <p>
            <ul>
              <li>przełamywanie lęku wobec futrzaków</li>
              <li>rozwijamy motorykę dużą i małą</li>
              <li>wzmacniamy i budujemy funkcje poznawcze,</li>
              <li>stymulacja zmysłów realizowana "przy okazji" (m.in. wzrok, słuch,
                dotyk), ćwiczymy koncentrację uwagi (zazwyczaj zapatrzenie w psa i
                dobra zabawa załatwia temat holistyczne)</li>
              <li>rozwijamy myślenie przyczynowo-skutkowe</li>
              <li>stymulujemy pracę mięśni poprzez wykonywanie ćwiczeń ruchowych,
                frajda podopiecznych i psich przyjaciół</li>
              <li>budujemy poczucie pewności siebie, własnej wartości i bezpieczeństwa</li>
              <li>podkręcany aktywność, lub ja wyciszamy (według potrzeb)</li>
              <li>nauka samodzielności i odpowiedzialności za siebie i zwierzaka</li>
              <li>obecność psa tonizuje zachowania co bezpośrednio łagodzi stres</li>
              <li>spontaniczność u dzieci wycofanych to kolejny atut PsichWarsztatow</li>
              <li>motywacja i mobilizacja do podejmowania działań</li>
              <li>uczymy właściwych postaw w stosunku do zwierząt, których bardzo
                brakuje w industrialnym społeczeństwie</li>
              <li>wpajamy również zasad bezpiecznego postępowania z psami</li>
              <li>więzi emocjonalne u podopiecznych z trudnym kontaktem</li>
            </ul>
          </p>
          <div class="col-12 col-md-10 pt-0-md pt-5">
            <img src="../../../assets/gfx/oferta/dogoterapia1.jpg" class="img-fluid">
          </div>
          <div class="col-12 col-md-10 pt-0-md pt-5">
            <img src="../../../assets/gfx/oferta/dogoterapia2.jpg" class="img-fluid">
          </div>
          <div class="col-12 col-md-10 pt-0-md pt-5">
            <img src="../../../assets/gfx/oferta/dogoterapia3.jpg" class="img-fluid">
          </div>`
      },
      {
        name: 'travelWorkshops',
        displayName: 'warsztaty podróżnicze',
        text: `<p><strong>Warsztaty podróżnicze - "Smocza Indonezja"</strong><br/>
            Na dzisiejszych warsztatach przedszkolaki poznały smoki zamieszkujące indonezyjskie
              lasy, plaże i głębiny morskie. Dzieci uczestniczyły również w spektaklu wayang - taniec cieni.
              Na koniec spotkania przedszkolaki przymierzały kolorowe stroje batikowe oraz zagrały na
              instrumentach z Jawy, Sumatry i Bali. Dziękujemy za wspaniałe warsztaty!
          </p>
          <div class="row">
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze1.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze2.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze3.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze4.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze5.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze6.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze7.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze8.jpg" class="img-fluid">
            </div>
            <div class="col-12 col-md-6 pt-0-md pt-5">
              <img src="../../../assets/gfx/oferta/warsztatypodroznicze9.jpg" class="img-fluid">
            </div>
          </div>`
      },
      {
        name: 'schedule',
        displayName: 'plan zajęć',
        text: `<p><h5><strong>Rozkład dnia</strong></h5></p>
          <div class="row">
            <p class="col-md-3 col-5">7:30 - 8:15</p>
            <p class="col-md-9 col-7">schodzenie się dzieci, czytanie bajek,
              zabawy dowolne</p>
            <p class="col-md-3 col-5">8:15 - 8:30</p>
            <p class="col-md-9 col-7">zabawy ruchowe, przygotowanie do śniadania</p>
            <p class="col-md-3 col-5">8:30 - 9:00</p>
            <p class="col-md-9 col-7">śniadanie</p>
            <p class="col-md-3 col-5">9:00 - 10:30</p>
            <p class="col-md-9 col-7">zajęcia dydaktyczne (język angielski,
              rytmika, gimnastyka korekcyjna - w zależności od dnia tygodnia)</p>
            <p class="col-md-3 col-5">10:30 - 11:45</p>
            <p class="col-md-9 col-7">zabawy dowolne, spacery, zabawy na boisku
              lub placu zabaw</p>
            <p class="col-md-3 col-5">11:45 - 12:00</p>
            <p class="col-md-9 col-7">przygotowanie do obiadu</p>
            <p class="col-md-3 col-5">12:00 - 13:00</p>
            <p class="col-md-9 col-7">obiad, czynności samoobsługowe</p>
            <p class="col-md-3 col-5">13:00 - 14:00</p>
            <p class="col-md-9 col-7">leżakowanie</p>
            <p class="col-md-3 col-5">14:00 - 14:30</p>
            <p class="col-md-9 col-7">podwieczorek</p>
            <p class="col-md-3 col-5">14:30 - 15:15</p>
            <p class="col-md-9 col-7">zajęcia popołudniowe, prace plastyczne,
              praca indywidualna z dzieckiem</p>
            <p class="col-md-3 col-5">15:30 - 17:30</p>
            <p class="col-md-9 col-7">zabawy według zainteresowań dzieci,
              rozchodzenie się dzieci do domów</p>
          </div>
          <br>
          <span><h5><strong>Plan zajęć dodatkowych</strong></h5></span>
          <div class="row">
            <span class="col-12"><strong>Poniedziałek:</strong></span>
            <span class="col-md-3 col-5">11:00 - 12:00</span>
            <span class="col-md-9 col-7">Judo</span>
            <span class="col-md-3 col-5">14:00 - 17:00</span>
            <p class="col-md-9 col-7">Logopeda</p>
            <span class="col-12"><strong>Wtorek:</strong></span>
            <span class="col-md-3 col-5">10:00 - 11:00</span>
            <span class="col-md-9 col-7">Język angielski</span>
            <span class="col-md-3 col-5">10:30 - 11:30</span>
            <p class="col-md-9 col-7">Przedszkoliada</p>
            <span class="col-12"><strong>Środa:</strong></span>
            <span class="col-md-3 col-5">10:00 - 11:00</span>
            <p class="col-md-9 col-7">Język angielski</p>
            <span class="col-12"><strong>Czwartek:</strong></span>
            <span class="col-md-3 col-5">11:00 - 12:00</span>
            <span class="col-md-9 col-7">Rytmika</span>
            <span class="col-md-3 col-5">14:00 - 17:00</span>
            <p class="col-md-9 col-7">Logopeda</p>
            <span class="col-12"><strong>Piątek:</strong></span>
            <span class="col-md-3 col-5">9:15 - 10:15</span>
            <span class="col-md-9 col-7">Gimnastyka korekcyjna</span>
            <span class="col-md-3 col-5">10:30 - 11:30</span>
            <span class="col-md-9 col-7">Warsztaty artystyczne</span>
          </div>`
      },
      {
        name: 'building',
        displayName: 'budynek',
        text: `
          <div class="container no-gutters p-0">
              <div class="row">
                <div class="col-12 pt-0-md pt-5">
                  <img class="img-responsive col-12" src="../../../assets/gfx/oferta/photo1.jpg">
                </div>
                <div class="col-12 pt-0-md pt-5">
                  <img class="img-responsive col-12" src="../../../assets/gfx/oferta/photo2.jpg">
                </div>
                <div class="col-12 pt-0-md pt-5">
                   <img class="img-responsive col-12" src="../../../assets/gfx/oferta/photo3.jpg">
                </div>
              </div>
            </div>`
      },
      {
        name: 'priceList',
        displayName: 'cennik',
        text: `<p><strong>Jednorazowa opłata wpisowa w wysokości 400,00 zł
            </strong></p>
          <p><strong>Czesne w wysokości 710,00 zł miesięcznie</strong><br>
            Zajęcia w cenie czesnego obejmują podstawę programową, język angielski,
            rytmikę oraz zajęcia logopedyczne, gimnastykę, warsztaty artystyczne,
            zajęcia ruchowe z elementami judo oraz zajęcia psychoedukacyjne.</p>
          <p>Całodzienne wyżywienie w cenie 13,80 zł<br>
            (śniadanie, drugie śniadanie, obiad, podwieczorek).</p>
          <p>Wpisowe to opłata jednorazowa przeznaczona na materiały plastyczne,
            dydaktyczne oraz środki higieniczne dla dzieci.`
      },
      {
        name: 'catering',
        displayName: 'oferta cateringowa',
        text: `<p><strong>Przykładowy jadłospis z 22-26 marca 2021</strong></p>
        <p><u>Poniedziałek 22.03</u><strong> (992 kcal)</strong><br>
          <strong>Śniadanie:</strong> Chleb graham (40g) <strong>[zawiera gluten]
          </strong> z masłem (5g) <strong>[zawiera mleko]</strong>, szynka z indyka
          (15g), pomidor (10g), papryka (10g), herbata owocowa / kakao (150g)
          <strong>[zawiera mleko]. (284 kcal)
          <br>
            II Śniadanie:</strong> Gruszka (50g) <strong>(27kcal)
          <br>
          Obiad:</strong> Krupnik z kaszą jęczmienną (250g)<strong>
          [zawiera seler, gluten]</strong>, makaron penne z kurczakiem i sosem
          carbonara (240g) <strong>[zawiera gluten, mleko]</strong>, jabłko (30g)
          <strong>[zawiera mleko]</strong>, kompot (150g). <strong>(506 kcal)
          <br>
          Podwieczorek:</strong> Bułka kajzerka (30g) <strong>[zawiera gluten]</strong>
          z masłem (5g) <strong>[zawiera mleko]</strong>, ser żółty (12g)<strong>
          [zawiera mleko]</strong>, pomidor (10g), herbata (150g).<strong>
          (175 kcal)</strong></p>
        <p><u>Wtorek 23.03</u><strong> (1057 kcal)</strong>
          <br>
          <strong>Śniadanie:</strong> Płatki kukurydziane na mleku (150g) <strong>
          [zawiera mleko]</strong>, chleb pszenno-żytni (30g) <strong>[zawiera gluten]
          </strong>, masło (5g) <strong>[zawiera mleko]</strong> szynka drobiowa (15g),
          ogórek (10g), sałata lodowa (2g), herbata z sokiem malinowym (150g).
          <strong>(281 kcal)</strong>
          <br>
          <strong>II Śniadanie:</strong> Banan (50g) <strong>(48 kcal)</strong><br>
          <strong>Obiad: </strong>Zupa gulaszowa (250g) <strong>[zawiera seler, gluten],
          </strong> ryż zapiekany z jabłkami, cynamonem i sosem jogurtowo-waniliwoym
           (220g) <strong>[zawiera mleko]</strong>, jabłko (30g), kompot (150g).
          <strong>(574 kcal)</strong><br>
          <strong>Podwieczorek:</strong> Bułka graham (30g) <strong>[zawiera gluten]
          </strong> z masłem (5g) <strong>[zawiera mleko]</strong>, ser biały (15g)
           <strong>[zawiera mleko]</strong>, pomidor (12g), herbata z dzikiej róży
          (150g). <strong>(155 kcal)</strong></p>
        <p><u>Środa 24.03</u><strong> (1041 kcal)
          <br>
          Śniadanie:</strong> Chleb słonecznikowy (40g) <strong>[zawiera gluten],
          </strong> z masłem (5g) <strong>[zawiera mleko]</strong>, pasztet pieczony
          (15g), ogórek kiszony (8g), pomidor (10g), herbata z cytryną / kawa zbożowa
          (150g) <strong>[zawiera mleko, gluten]. (314 kcal)
          <br>
          II Śniadanie:</strong> Jabłko (50g) <strong>(23 kcal)
          <br>
          Obiad:</strong> Zupa ziemniaczana (250g) <strong>[zawiera seler,
          mleko]</strong>, kasza bulgur (80g) <strong>[zawiera gluten]</strong>,
          schab duszony w sosie (80g) <strong>[zawiera gluten]</strong>, surówka
          z selera (80g) <strong>[zawiera seler, mleko]</strong>, kompot (150g).
          <strong>(550 kcal)
          <br>
          Podwieczorek:</strong> Jogurt naturalny z bananem i granolą czekoladową
          (150g) <strong>[zawiera mleko, gluten]. (160 kcal)</strong></p>
        <p><u>Czwartek 25.03</u><strong> (996 kcal)
          <br>
          Śniadanie:</strong> Kasza manna na mleku (150g) <strong>[zawiera mleko,
          gluten]</strong>, bułka pszenna (30g) <strong>[zawiera gluten]</strong>
          z masłem (5g) <strong>[zawiera mleko]</strong>, polędwica sopocka (12g),
          rzodkiewka (10g), szczypiorek (3g), herbata żurawinowa (150g). <strong>
          (267 kcal)
          <br>
          II Śniadanie:</strong> Kiwi (50g) <strong>(28 kcal)
          <br>
          Obiad:</strong> Zupa pomidorowa z papryką z makaronem (250g) <strong>
          [zawiera seler, gluten]</strong>, naleśnik z serem białym i musem owocowym
          (150g) <strong>[zawiera gluten, jaja, mleko]</strong>, kompot(150g) <strong>
          (531 kcal)
          <br>
          Podwieczorek:</strong> Muffinki czekoladowe (60g) <strong> [zawiera mleko,
          jaja, gluten]</strong>, herbata z cytryną (150g). <strong>(161 kcal)</strong></p>
        <p><u>Piątek 26.03</u><strong> (1048 kcal)
          <br>
          Śniadanie:</strong> Chleb pszenny z ziarnami zbóż (40g) <strong>[zawiera
          gluten]</strong> z masłem (5g) <strong>[zawiera mleko]</strong>, pasta z
          jaj z natką pietruszki (20g) <strong>[zawiera jaja, mleko]</strong>, ogórek
          (10g), herbata z dzikiej róży / kakao (150g) <strong>[zawiera mleko]. (286 kcal)
          <br>
          II Śniadanie:</strong> Klementynka (50g) <strong>(21 kcal)
          <br>
          Obiad:</strong> Jarzynowa z kaszą pęczak (250g) <strong>[zawiera gluten,
          seler]</strong>, ziemniaki (100g) <strong>[zawiera mleko]</strong>, paluszki
          rybne (90g) <strong>[zawiera rybę, jaja, gluten]</strong>, surówka z czerwonej
          kapusty (80g), kompot (150g). <strong>(510 kcal)
          <br>
          Podwieczorek:</strong> Ciasto drożdżowe (50g) <strong>[zawiera gluten,
          jaja, mleko]</strong>, masło (5g) <strong>[zawiera mleko]</strong>,
          kawa zbożowa (150g) <strong>[zawiera mleko, gluten]. (231 kcal)</strong>`
      }
    ];
    this.isMobileDevice = false;
    this.backgroundImages = '';
  }

  ngOnInit() {
    this.setBackground();
    const initialSection = this.route.snapshot.paramMap.get('section');
    const showActivities = initialSection === 'activities';
    if (initialSection === null) {
      this.changeContent(this.contentList[0].name, showActivities);
    } else {
      const initialContentObject = this.contentList.filter(element => element.name === initialSection)
      this.changeContent(initialContentObject[0].name, showActivities);
    }
    $('li').click(() => {
      $('.collapse').collapse('hide');
    });
    $('ol a').on('click', function (event: any) {
      $(event).parent().toggleClass('show');
    });
    $('ol a').on('click', function (event: any) {
      event.stopPropagation();
    });
  }

  changeContent(section: string | null, mobile: boolean, event?: any): void {
    if (event) {
      $('.selected').removeClass('selected');
      event.target.classList.add('selected');
    }
    window.scroll(0, 0);
    try {
      var text = this.contentList.find(x => x.name === section).text;
      var title = this.contentList.find(x => x.name === section).displayName;
      if (mobile) {
        $('#contentTitle').css({ 'display': 'none' });
        $('#activitiesHeader').css({ 'display': 'block' });
      } else {
        $('#contentTitle').css({ 'display': 'block' });
        $('#activitiesHeader').css({ 'display': 'none' });
        $('#mobileActivitiesMenu').css({ 'display': 'none' });
      }
    } catch {
      console.log('Error while loading content');
    }
    this.contentTitle = title;
    this.content = text;
  }

  getDeviceInfo(): void {
    this.isMobileDevice = this.isMobileService.getDeviceInfo();
  }

  setBackground() {
    this.getDeviceInfo();
    if (this.isMobileDevice === true) {
      this.backgroundImages = '';
    } else {
      this.backgroundImages = `<img src="../../assets/gfx/oferta/orangesplash1.png" id="img6">
        <img src="../../assets/gfx/oferta/orangesplash.png" id="img7">
        <img src="../../assets/gfx/oferta/whiteman.png" id="img8">
        <img src="../../assets/gfx/oferta/whitecat.png" id="img9">`;
    }
  }

}